import one from "../assets/images/image6.jpg";
import liniaSn15 from "../assets/images/IMG_0036.JPG";
import liniaSn15secondImg from "../assets/images/IMG_0068.JPG";
import wn110kv from "../assets/images/WDRU2353.JPG";
import st15 from "../assets/images/IMG_0245.JPG";
import sn6kV from "../assets/images/KUYV4654.JPG";
import kanal from "../assets/images/KTNO9683.JPG";
import kanalsn15 from "../assets/images/IMG_3281.JPG";
import st1504kV from "../assets/images/IMG_3032.JPG";

export const projects = [
  {
    id: 1,
    project: liniaSn15,
    images: [liniaSn15, liniaSn15secondImg],
    title: "Budowa linii SN 15 kV",
    show: false,
    desc: "Budowa napowietrznej linii SN 15 kV z przewodami w osłonie izolacyjnej",
  },
  {
    id: 2,
    project: wn110kv,
    images: [wn110kv],
    title: "Wymiana słupa WN 110 kV",
    show: false,
    desc: "Budowa uziemienia dla wymienianego słupa WN 110 kV",
  },
  {
    id: 3,
    project: st15,
    images: [st15],
    title: "Modernizacja ST 15/0,4 kV - FDIR",
    show: false,
    desc: "Modernizacja ST 15/0,4 kV pod systemem FDIR oraz linii kablowej SN wraz z rozruchem automatyki",
  },
  {
    id: 4,
    project: sn6kV,
    images: [sn6kV],
    title: "Linia kablowa SN 6 kV",
    show: false,
    desc: "Budowa przyłącza SN 6 kV oraz linii kablowej nn 0,4 kV",
  },
  {
    id: 5,
    project: kanal,
    images: [kanal],
    title: "Kanalizacja teletechniczna",
    show: false,
    desc: "Budowa kanalizacji teletechnicznej wraz z linią SN 15 kV",
  },
  {
    id: 6,
    project: kanalsn15,
    images: [kanalsn15],
    title: "Kanalizacja teletechniczna",
    show: false,
    desc:
      "Budowa kanalizacji teletechnicznej, linii kablowej SN 15 kV oraz nn 0,4 kV",
  },

  {
    id: 7,
    project: st1504kV,
    images: [st1504kV],
    title: "ST 15/0,4 kV",
    show: false,
    desc:
      "Budowa napowietrznej ST 15/0,4 kV z pomiarem pośrednim wraz z przyłączem SN 15 kV.",
  },
];
