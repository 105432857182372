import React, { useState } from "react";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  StyledForm,
  StyledField,
  StyledTextArea,
  StyledFieldCheckbox,
  StyledErrorName,
  StyledErrorMail,
  StyledErrorSubject,
  StyledErrorTextArea,
  StyledErrorCheckbox,
  StyledButtonWrapper,
  StyledButton,
  StyledInputContainer,
  StyledCheckboxWrapper,
  StyledPrivacyPolicy,
} from "./styles/ContactFormStyles";
import emailjs from "emailjs-com";

const ContactForm = () => {
  const formValidationSchema = Yup.object().shape({
    name: Yup.string().required("Proszę o podanie imienia i nazwiska."),
    email: Yup.string()
      .email("Podany adres e-mail nie jest poprawny.")
      .required("Proszę o podanie adresu e-mail."),
    subject: Yup.string().required("Proszę o podanie tematu."),
    message: Yup.string().required("Proszę o wpisanie treści wiadomości. "),
    acceptTerms: Yup.boolean().oneOf(
      [true],
      "Musisz wyrazić zgodę na przetwarzanie danych."
    ),
  });

  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <Formik
        initialValues={{
          name: "",
          email: "",
          message: "",
          subject: "",
          acceptTerms: false,
        }}
        validationSchema={formValidationSchema}
        onSubmit={(values, { resetForm }) => {
          emailjs
            .send(
              process.env.REACT_APP_SERVICE_ID,
              process.env.REACT_APP_TEMPLATE_ID,
              values,
              process.env.REACT_APP_USER_ID
            )
            .then(function (response) {
              alert("Wiadomość została wysłana");
            })
            .then(() => window.location.reload())
            .catch(() =>
              alert(
                "Przepraszamy... coś poszło nie tak, proszę spróbować jeszcze raz"
              )
            );

          resetForm();
        }}
      >
        {(values) => (
          <StyledForm>
            <StyledInputContainer>
              <StyledErrorName>
                <ErrorMessage name="name" />
              </StyledErrorName>
              <StyledField
                type="text"
                name="name"
                placeholder="Imię i nazwisko"
                id="name"
                value={values.name}
              />
              <StyledErrorMail>
                <ErrorMessage name="email" />
              </StyledErrorMail>
              <StyledField
                type="email"
                name="email"
                placeholder="Adres e-mail"
                id="email"
                value={values.email}
              />
            </StyledInputContainer>
            <StyledInputContainer>
              <StyledErrorSubject>
                <ErrorMessage name="subject" />
              </StyledErrorSubject>
              <StyledField
                type="text"
                name="subject"
                placeholder="Temat wiadomości"
                id="subject"
                value={values.subject}
              />
            </StyledInputContainer>
            <StyledInputContainer>
              <StyledErrorTextArea>
                <ErrorMessage name="message" />
              </StyledErrorTextArea>
              <StyledTextArea
                component="textarea"
                name="message"
                id="message"
                placeholder="Treść wiadomości"
                value={values.message}
              />
            </StyledInputContainer>
            <StyledInputContainer>
              <StyledErrorCheckbox>
                <ErrorMessage name="acceptTerms" />
              </StyledErrorCheckbox>
              <StyledCheckboxWrapper>
                <StyledFieldCheckbox type="checkbox" name="acceptTerms" />
                {/* <label>
                  Wyrażam zgodę na przetwarzanie moich danych zgodnie z
                  <StyledPrivacyPolicy>
                    polityką prywatności.
                  </StyledPrivacyPolicy>
                </label> */}
                <label>
                  Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie
                  z polityką prywatności.
                  <p
                    onClick={() => setShowMore(!showMore)}
                    style={{
                      color: "blue",
                      cursor: "pointer",
                    }}
                  >
                    {showMore ? "czytaj mniej..." : "czytaj więcej..."}
                  </p>
                </label>
              </StyledCheckboxWrapper>
            </StyledInputContainer>
            <StyledButtonWrapper>
              <StyledButton type="submit">Wyślij wiadomość</StyledButton>
            </StyledButtonWrapper>
            {showMore ? (
              <p style={{ marginTop: "30px" }}>
                Niniejszy dokument stanowi opis polityki prywatności zawartej na
                stronie internetowej pod adresem: www.gk-epb.pl, którego
                administratorem jest GK EPB Sp. z o.o. z siedzibą w Bydgoszczy
                przy ul. Tadeusza Kościuszki 27/13, 85-079 Bydgoszcz wpisana do
                Krajowego Rejestru Sądowego pod numerem KRS: 0000853352, REGON:
                386712170, NIP: 9671438225. Niniejszy dokument przedstawia
                zasady korzystania przez administratora strony internetowej
                www.gk-epb.pl z przetwarzanych na tej stronie danych osobowych,
                pozostawianych przez odwiedzających stronę. Gromadzone dane
                osobowe są wykorzystywane zgodnie z treścią niniejszej polityki
                prywatności i obowiązującymi przepisami o ochronie danych
                osobowych tj.: • Rozporządzenie Parlamentu Europejskiego i Rady
                (UE) 2016/679 z dnia 27.04.2016 r. w sprawie ochrony osób
                fizycznych w związku z przetwarzaniem danych osobowych i w
                sprawie swobodnego przepływu takich danych oraz uchylenia
                dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych)
                (Dz.U.UE.L.2016.119.1 z dnia 2016.05.04, dalej jako „RODO”), •
                Ustawa z dnia 10.05.2018 r. o ochronie danych osobowych (t.j.
                Dz. U. z 2019 r. poz. 1781), • Ustawa z dnia 16.07.2004 r. Prawo
                telekomunikacyjne (t.j. Dz. U. z 2019 r. poz. 2460 z późn. zm.).
                Państwa dane przetwarzane są w następujących sytuacjach: • w
                przypadku wyrażenia zgody na kontakt za pomocą formularza
                kontaktowego znajdującego się na stronie internetowej, • w
                procesie rekrutacji a także w przypadku zawarcia umowy, • w
                ramach współpracy podczas realizowania umowy. Wobec powyższego
                wykorzystywane są dane identyfikacyjne, teleadresowe oraz inne
                dane przekazane nam dobrowolnie przez Państwa w ramach
                współpracy. Serwis zbiera informacje dobrowolnie podane przez
                użytkownika. Jako administrator zapewniamy bezpieczeństwo danych
                osobowych dzięki zastosowaniu odpowiednich środków technicznych
                i organizacyjnych, mających na celu zapobieżenie bezprawnemu
                przetwarzaniu danych oraz ich przypadkowej utracie, zniszczeniu
                oraz uszkodzeniu. Nasza strona internetowa używa plików cookies
                (tzw. ciasteczka) w celach funkcjonalnych. Dzięki nim możemy
                indywidualnie dostosować stronę do Państwa potrzeb. Każdy może
                zaakceptować pliki cookies albo ma możliwość wyłączenia ich w
                przeglądarce, dzięki czemu nie będą zbierane żadne informacje.
                Wszelkie pytania związane przetwarzaniem i ochroną danych
                osobowych, w tym dotyczące niniejszej polityki prywatności
                prosimy kierować na adres: biuro@gk-epb.pl. W Serwisie mogą
                pojawiać się linki do innych stron internetowych. Takie strony
                internetowe działają niezależnie od Serwisu i nie są w żaden
                sposób nadzorowane przez serwis www.gk-epb.pl. Strony te mogą
                posiadać własne polityki dotyczące prywatności oraz regulaminy,
                z którymi zalecamy się zapoznać. Zastrzegamy sobie prawo do
                zmiany w polityce ochrony prywatności serwisu, na które może
                wpłynąć rozwój technologii internetowej, ewentualne zmiany prawa
                w zakresie ochrony danych osobowych oraz rozwój naszego serwisu
                internetowego. O wszelkich zmianach będziemy informować w sposób
                widoczny i zrozumiały.
              </p>
            ) : null}
          </StyledForm>
        )}
      </Formik>
    </>
  );
};
export default ContactForm;
