import transformer from "../assets/icons/transformer.svg";
import powerLine from "../assets/icons/power-line.svg";
import solarPanel from "../assets/icons/solar-panel.svg";
import plugs from "../assets/icons/plugs.svg";

export const offers = [
  {
    id: 1,
    img: powerLine,
    description: [
      `Projektowanie oraz budowa kablowych i napowietrznych linii 110 kV, 220 kV i 400 kV`,
      `Usługi dronami w zakresie budowy linii WN, SN oraz skanowanie LiDAR i inne`,
      `Oględziny linii WN, SN oraz nadzory inwestorskie i usługi BHP`,
      `Prowadzenie prac pod napięciem na liniach napowietrznych 220 kV i 400 kV`,
      `Kompleksowe pomiary linii kablowych WN, SN, nn wraz z wykrywaniem WNZ oraz lokalizacją uszkodzeń`,
      `Prowadzenie prac pod napięciem na liniach kablowych i napowietrznych nn 0,4 kV`,
      `Montaże muf i głowic WN, SN`,
    ],
    showDesc: true,
  },
  {
    id: 2,
    img: transformer,
    description: [
      `Projektowanie, budowa oraz modernizacja stacji transformatorowych WN/SN i SN/nn`,
      `Projektowanie i budowa złączy kablowych ZK SN wraz z telemechaniką`,
      `Projektowanie i przebudowa stacji SN/nn pod system automatyki FDIR`,
      `Konfiguracja automatyki zabezpieczeniowej`,
    ],

    showDesc: false,
  },
  {
    id: 3,
    img: solarPanel,
    description: [
      `Budowa i projektowanie farm fotowoltaicznych oraz wiatrowych`,
      `Budowa, projektowanie i uruchomienie przemysłowych instalacji fotowoltaicznych w systemie "pod klucz"`,
      `Panele fotowoltaiczne dla indywidulanych odbiorców w systemie "zaprojektu i wybuduj"`,
    ],
    showDesc: false,
  },
  {
    id: 4,
    img: plugs,
    description: [
      `Obsługa inwestorów w zakresie przyłączy WN, SN, nn od uzyskania warunków przyłączenia po budowę i zawarcie umów z OSP lub OSD `,
      `Budowa obiektów energetycznych WN/SN/nn w systemie "zaprojektuj i wybuduj"`,
      `Badania, diagnostyka i uruchomienie automatyki energetycznej`,
      `Budowa oraz modernizacja wnętrzowych instalacji elektrycznych 0,4 kV`,
    ],
    showDesc: false,
  },
];
