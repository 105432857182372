import React from "react";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import withReveal from "react-reveal/withReveal";

const StyledSideContactContainer = withReveal(
  styled.div`
    margin-top: 90px;
  `,
  <Fade left />
);

const StyledSideContactHeader = styled.h3`
  text-transform: uppercase;
`;
const StyledSideContactCompanyName = styled.h3`
  color: #888;
  font-weight: 400;
`;
const StyledSideContactAddress = styled.p`
  color: #888;
  font-weight: 300;
`;

const StyledBottomContactAddress = styled.div`
  margin-top: 30px;
`;
const StyledMailPhone = styled.span`
  color: #2e92ee;
  margin-left: 3px;
  cursor: pointer;
`;

const SideContact = () => {
  return (
    <>
      <StyledSideContactContainer>
        <StyledSideContactHeader>Dane kontaktowe</StyledSideContactHeader>
        <div>
          <StyledSideContactCompanyName>
            GK EPB Sp. z o.o.
          </StyledSideContactCompanyName>
          <StyledSideContactAddress>
            ul. Tadeusza Kościuszki 27/13
          </StyledSideContactAddress>
          <StyledSideContactAddress>85-079 Bydgoszcz</StyledSideContactAddress>
        </div>
        <StyledBottomContactAddress>
          <StyledSideContactAddress>
            e-mail: <StyledMailPhone>biuro@gk-epb.pl</StyledMailPhone>
            </StyledSideContactAddress>
            <StyledSideContactAddress>
            Tel.No. <StyledMailPhone>+48 667 472 074</StyledMailPhone>
          </StyledSideContactAddress>
          {/* <StyledSideContactAddress>
            tel. <StyledMailPhone>+48 000 00 00 00</StyledMailPhone>
          </StyledSideContactAddress> */}
        </StyledBottomContactAddress>
      </StyledSideContactContainer>
    </>
  );
};

export default SideContact;
